import { useSession } from 'next-auth/react';

import { Link } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeCartShippingHeaderProps } from './types';

const DownloadInvoice = ({
  orderId,
}: {
  orderId: TypeCartShippingHeaderProps['orderId'];
}) => {
  const { data, status } = useSession();

  return status === 'authenticated' ? (
    <Link
      download
      href={`/api/invoice/${orderId}?token=${(data as any)?.accessToken}`}
    >
      Descargar albarán
    </Link>
  ) : null;
};

/**
 * CartShippingHeader
 */
export const CartShippingHeader: FunctionComponent<
  TypeCartShippingHeaderProps
> = ({
  className = '',
  title,
  description,
  orderId,
}: TypeCartShippingHeaderProps) => (
  <div
    className={`u-body flex items-center justify-between gap-6 bg-primary-100 px-3 py-2 text-primary-900 outline outline-1 outline-primary-100 md:p-4 ${className}`}
  >
    <div>
      <p className="u-body--s font-bold">{title}</p>

      {description && <p className="u-body--xs mt-1">{description}</p>}
    </div>

    {orderId && <DownloadInvoice orderId={orderId} />}
  </div>
);

CartShippingHeader.displayName = 'CartShippingHeader';
